import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "col-12 mt-3" }

import { computed, onMounted, ref } from "vue";

import { useRoute } from "vue-router";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { Erreur } from "@/utils/Erreur";
import RecadreurImage from "@/outil-compposant/RecadreurImage.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuConfiguration',
  setup(__props) {

const route = useRoute()
const id = Number(route.params.id);
const storeIndividuPresentation = useIndividuPresentationStore()
const nouveauPseudonyme = ref("")
const nouveauTextePresentation = ref("")
const nouvelleImageContenu = ref("");
const imageErreurTaille = ref<Erreur|null>(new Erreur("blu"));
const imageErreurDimension = ref<Erreur|null>(null);
const estErreurTaille = ref(false);
const estErreurDimension = ref(false);
const afficheRecadreurImage = ref(false)

const onImageRecadre = (imageRecadree: string) => {
  nouvelleImageContenu.value = imageRecadree; // Stocker l'image recadrée
  afficheRecadreurImage.value = false; // Fermer la modal après recadrage
};

const afficherRecadrerImage = () => afficheRecadreurImage.value=true;

const estPseudonymeErreur = computed(() => {
  return nouveauPseudonyme.value.length == 0 || nouveauPseudonyme.value.length > 30
})
const estPresentationErreur = computed(() => {
  if(!nouveauTextePresentation.value) return false;
  return nouveauTextePresentation.value.length > 500
})
const modifierInfoPub = async () => {
   await storeIndividuPresentation.modifier(nouveauPseudonyme.value, nouveauTextePresentation.value, nouvelleImageContenu.value);
}


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    if (storeIndividuPresentation.individuSelectionne) {
      nouveauPseudonyme.value = storeIndividuPresentation.individuSelectionne.pseudonyme
      nouveauTextePresentation.value = storeIndividuPresentation.individuSelectionne.presentation
      nouvelleImageContenu.value = storeIndividuPresentation.individuSelectionne.imagePresentation
    }
  } catch (error) {
    console.log("OnMounted Error");
  }
})

return (_ctx: any,_cache: any) => {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, { title: "Vos informations publiques" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_form, {
        onSubmit: _withModifiers(modifierInfoPub, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_group, { label: "Changer le pseudonyme" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_input, {
                type: "text",
                modelValue: nouveauPseudonyme.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((nouveauPseudonyme).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_alert, {
            modelValue: estPseudonymeErreur.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((estPseudonymeErreur).value = $event)),
            variant: "danger",
            dismissible: "true"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Pseudonyme trop long, maximum 30 caractères")
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_form_group, { label: "Changer le texte de la présentation" }, {
            default: _withCtx(() => [
              _createVNode(_unref(QuillEditor), {
                content: nouveauTextePresentation.value,
                "onUpdate:content": _cache[2] || (_cache[2] = ($event: any) => ((nouveauTextePresentation).value = $event)),
                theme: "snow",
                contentType: "html"
              }, null, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_alert, {
            modelValue: estPresentationErreur.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((estPresentationErreur).value = $event)),
            variant: "danger",
            dismissible: "true"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Texte trop long, maximum 500 caractère, balises html comprises")
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_b_button, {
                  onClick: _withModifiers(afficherRecadrerImage, ["prevent"])
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Changer l'image")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_b_img, { src: nouvelleImageContenu.value }, null, 8, ["src"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_alert, {
            modelValue: estErreurTaille.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((estErreurTaille).value = $event)),
            variant: "danger",
            dismissible: "true"
          }, {
            default: _withCtx(() => [
              _createTextVNode("\"" + _toDisplayString(imageErreurTaille.value?.message) + "\"", 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_alert, {
            modelValue: estErreurDimension.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((estErreurDimension).value = $event)),
            variant: "danger",
            dismissible: "true",
            class: "mt-3"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(imageErreurDimension.value?.message), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_alert, {
            modelValue: _unref(storeIndividuPresentation).estErreurSelection,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(storeIndividuPresentation).estErreurSelection) = $event)),
            variant: "danger",
            dismissible: "true"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(storeIndividuPresentation)?.erreurSelection?.message), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_modal, {
            modelValue: afficheRecadreurImage.value,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((afficheRecadreurImage).value = $event)),
            title: "Découpez votre image",
            size: "lg",
            "hide-footer": "true"
          }, {
            default: _withCtx(() => [
              _createVNode(RecadreurImage, {
                "hauteur-stencil": 400,
                largeurStencil: 400,
                onRecadre: onImageRecadre
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_button, {
            type: "submit",
            variant: "primary",
            disabled: estPseudonymeErreur.value || estPresentationErreur.value || estErreurTaille.value || estErreurDimension.value
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Sauvegarder les modifications ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})