export class Erreur {
  message: string;

  constructor(error: any) {
    this.message = ''
    if(typeof error === "string"){
      this.message = error
    }
    if (error && typeof error === 'object' &&
      'response' in error && 'status' in error.response) {
      console.log(error.response)
      if (error.response.status != 500) {
        this.message = "problème d'accès au serveur";
      } else if (error.response.data !== undefined && error.response.data.message !== undefined){
        this.message = error.response.data.message
      } else  if(error.response.map !==undefined){
        this.message = error.message
      }
    }
  }
}

