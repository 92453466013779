import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contenant" }

import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import DemandeCreationCompteComposant from "@/pages/DemandeCreationCompte.vue";
import DemandeRecuperationCompte from "@/pages/DemandeRecuperationCompte.vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";

export default /*@__PURE__*/_defineComponent({
  __name: 'ConnexionCompte',
  setup(__props) {

const router = useRouter();
const courriel = ref("");
const password = ref("");
const individuConnectestore = useIndividuConnecteStore()
const gestionCompteStore = useGestionCompteStore()
const isCreationModalVisible = ref(false);
const isRecuperationModalVisible = ref(false);
const isErreurConnexion = ref(false)
const messageErreurConnexion = ref("")
const showCreationModal = () => {
  isCreationModalVisible.value = true;
};
const showRecuperationModal = () => {
  isRecuperationModalVisible.value = true;
};
const connexion = async () => {
  const resultatConnexion = await gestionCompteStore.connexion(courriel.value, password.value);
  if (resultatConnexion.erreur != null) {
    isErreurConnexion.value = true
    messageErreurConnexion.value = resultatConnexion.erreur.message;
    return;
  }
  isErreurConnexion.value = false;
  await individuConnectestore.connecterIndividu()
  if(individuConnectestore.individuConnecte != null) {
    await router.push({ path: '/' });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_card, {
      title: "Connexion",
      tag: "article",
      class: "contenu"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_form, {
          onSubmit: _withModifiers(connexion, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_group, {
              label: "Email",
              "label-for": "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  id: "email",
                  modelValue: courriel.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
                  type: "email",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_form_group, {
              label: "Password",
              "label-for": "password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  id: "password",
                  modelValue: password.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                  type: "password",
                  required: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_alert, {
              "v-model": _unref(individuConnectestore).erreurConnexion == null,
              variant: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(individuConnectestore).erreurConnexion?.message), 1)
              ]),
              _: 1
            }, 8, ["v-model"]),
            _createVNode(_component_b_button, {
              type: "submit",
              variant: "primary"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Connexion")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("p", null, [
          _createVNode(_component_b_link, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showCreationModal()))
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Créer un compte")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("p", null, [
          _createVNode(_component_b_link, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (showRecuperationModal()))
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("J'ai oublié mon mot de passe")
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_b_modal, {
          id: "modal-1",
          title: "Création de compte",
          modelValue: isCreationModalVisible.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isCreationModalVisible).value = $event)),
          "hide-footer": "true"
        }, {
          default: _withCtx(() => [
            _createVNode(DemandeCreationCompteComposant)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_b_modal, {
          id: "modal-1",
          title: "Récupération de compte",
          modelValue: isRecuperationModalVisible.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isRecuperationModalVisible).value = $event)),
          "hide-footer": "true"
        }, {
          default: _withCtx(() => [
            _createVNode(DemandeRecuperationCompte)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})