<template>
  <b-container class="mt-5">
    <b-row class="justify-content-md-center">
      <b-col md="6">
        <b-card title="Finalisation de la modification du mot de passe">
          <b-alert v-model="isErreurRecupInfo" variant="danger">{{ messageErreurRecupInfo }}</b-alert>
          <b-alert v-model="isErreurCode" variant="warning">Le code ne semble pas être formatté correctement</b-alert>
          <b-form @submit.prevent="recuperationCompte">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="courriel" type="email" required disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Password" label-for="password">
              <div style="display: inline-flex;">
                <b-form-input id="password" v-model="password" :type="motPasseVisible ? 'text' : 'password'"
                              style="display: flex ;"
                              required @input="evaluationPassword" :state="isPaswordValid"/>
                <b-button type="button" size="sm" variant="primary" @click="modifierMotPasseVisible"
                          style="display: flex ; ">
                  <i v-if="motPasseVisible" class="fa-solid fa-eye"></i>
                  <i v-if="!motPasseVisible" class="fa fa-eye-slash"></i>
                </b-button>
              </div>
            </b-form-group>
            <b-alert v-model="isErreurRecuperationCompte" variant="danger">{{ messageErreurRecuperationCompte }}
            </b-alert>
            <b-button type="submit" variant="primary" :disabled="!isPaswordValid || !isValitationEnabled">Modifier le mot de passe</b-button>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
import { ConfigVerifPassword} from "@/utils/ConfigVerifPassword";

const router = useRouter();
const password = ref('');
const courriel = ref('');
const isPaswordValid = ref(false);
const motPasseVisible = ref(false)
const verifPassword = new ConfigVerifPassword()
const modifierMotPasseVisible = () => motPasseVisible.value = !motPasseVisible.value
const gestionCompteStore = useGestionCompteStore()
const isErreurCode = ref(false);
const isErreurRecuperationCompte = ref(false);
const isErreurRecupInfo = ref(false);
const messageErreurRecuperationCompte = ref('')
const messageErreurRecupInfo = ref('')
const route = useRoute()
const code = computed(() => route.params.code)
const isValitationEnabled = ref(true)

onMounted(async () => {
  if (typeof code.value !== 'string') {
    isErreurCode.value = true;
    return;
  }
  let resultatRecup = await gestionCompteStore.donneCourrielIndividuByCode(code.value);
  if (resultatRecup.erreur != null) {
    messageErreurRecupInfo.value = resultatRecup.erreur.message
    return;
  }
  courriel.value = resultatRecup.courriel;
  isErreurRecupInfo.value = false;
});

const evaluationPassword = () => {
  isPaswordValid.value = verifPassword.isPasswordValid(password.value);
}

const recuperationCompte = async () => {
  isValitationEnabled.value = false;
  if (typeof code.value == 'string' && isPaswordValid.value) {
    const resultatRecuperationCompte = await gestionCompteStore.recuperationCompte(code.value, password.value)
    if (resultatRecuperationCompte.erreur != null) {
      messageErreurRecuperationCompte.value = resultatRecuperationCompte.erreur.message
      isErreurRecuperationCompte.value = true;
    } else if (!resultatRecuperationCompte.reussi) {
      isErreurRecuperationCompte.value = true;
      messageErreurRecuperationCompte.value = "La récupération du compte a échoué";
    } else {
      isErreurRecuperationCompte.value = false;
      await router.push({ path: '/' });
    }
  }
  isValitationEnabled.value = true
};


</script>