import { defineStore } from "pinia";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";

export const useGestionCompteStore = defineStore('gestionCompte', () => {
  const connexion = async (courriel: string, password: string) => {
    try {
      const response = await axiosInstance.post("/public/auth/connexion/", {
        courriel: courriel,
        password: password
      });
      return { reussi: true, erreur: null };
    } catch (error) {
      return { reussi: false, erreur: new Erreur(error) };
    }
  }
  const donneCourrielPreIndividuByCode = async (code: string) => {
    try {
      const reponse = await axiosInstance.get(
        "/public/gestioncompte/preindividu/" + code);
      return { courriel: reponse.data as string, erreur: null };

    } catch (error) {
      return { 'courriel': '' as string, erreur: new Erreur(error) };
    }
  }
  const creationCompte = async (code: string, pseudonyme: string, password: string) => {
    try {
      await axiosInstance.post('/public/gestioncompte/individu/' + code,  {
        password: password,
        pseudonyme: pseudonyme
      });
      return { reussi: true, erreur: null };
    } catch (error) {
      return { reussi: false, erreur: new Erreur(error) };
    }
  }
  const creationPreIndividu = async (courriel: string, isoDate: string) => {
    try {
      const reponse = await axiosInstance.post(
        "/public/gestioncompte/preindividu/" + courriel, null, {
          params: { 'dateNaissance': isoDate }
        }
      );
      return { reussi: reponse.data, erreur: null };
    } catch (error: unknown) {
      return { reussi: false, erreur: new Erreur(error) };
    }
  }
  const donneCourrielIndividuByCode = async (code: string) => {
    try {
      const reponse = await axiosInstance.get(
        "/public/gestioncompte/individu/" + code);
      return { courriel: reponse.data as string, erreur: null };

    } catch (error) {
      return { courriel: '' as string, erreur: new Erreur(error) };
    }
  }
  const demandeRecuperationCompte = async (courriel: string) => {
    try {
      const reponse = await axiosInstance.get(
        "/public/gestioncompte/individu/demanderecup/" + courriel
      );
      return { reussi: reponse.data, erreur: null }
    } catch (error) {
      return { reussi: false, erreur: new Erreur(error) };

    }
  }
  const recuperationCompte = async (code: string, password: string) => {
    try {
      await axiosInstance.post('/public/gestioncompte/individu/recup/' + code ,
        {password: password});
      return { reussi: true, erreur: null };
    }
  catch
  (error)
  {
    return { reussi: false, erreur: new Erreur(error) };
  }
}

return {
  connexion,
  donneCourrielPreIndividuByCode,
  creationCompte,
  creationPreIndividu,
  demandeRecuperationCompte,
  recuperationCompte,
  donneCourrielIndividuByCode
}
})
;