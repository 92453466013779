import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Ref, ref, watch } from "vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import frLocale from 'vue-datepicker-next/locale/fr';
import { useGestionCompteStore } from "@/store/gestion-compte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'DemandeCreationCompte',
  setup(__props) {

const afficherCreationOk = ref(false);
const afficherCreationEchec = ref(false);
const isErreurCreation = ref(false);
const messageErreurCreation = ref("")
const isErreurDate = ref(true);
const courriel = ref("");
const isCourrielValide = ref(false);
const isFormulaireValide = ref(false);
const isValitationEnabled = ref(true);
const gestionCompteStore = useGestionCompteStore()

const moinsQuinzeAns = () => {
  let maintenant = new Date();
  maintenant.setHours(0, 0, 0, 0);
  return new Date(maintenant.getTime() - 15 * 365 * 24 * 3600 * 1000)
}
const dateNaissance: Ref<null | Date> = ref(null)
const seulementAvantQuinzeAns = (date: Date) => {
  return date >= new Date(moinsQuinzeAns());
}
const evaluationEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100;
  evaluationFormulaire()
}
const evaluationDate = () => {
  console.log("evaluationDate")
  isErreurDate.value = dateNaissance.value == null
  evaluationFormulaire()
}
const evaluationFormulaire = () => {
  isFormulaireValide.value = isCourrielValide.value && !isErreurDate.value
}
const creation = async () => {
  isValitationEnabled.value = false
  afficherCreationOk.value = false
  if(isFormulaireValide.value && dateNaissance.value!=null) {
    const date = dateNaissance.value
    let isoDate = date.toISOString().split('T')[0];
    const resultat = await gestionCompteStore.creationPreIndividu(courriel.value, isoDate);
    if (resultat.erreur != null) {
      isErreurCreation.value = true
      afficherCreationOk.value = false
      afficherCreationEchec.value = false
      messageErreurCreation.value = resultat.erreur.message
    } else if (!resultat.reussi) {
      afficherCreationOk.value = false
      afficherCreationEchec.value = true
    } else {
      afficherCreationOk.value = true
      afficherCreationEchec.value = false
    }
  }
  isValitationEnabled.value= true
};
watch(dateNaissance, evaluationDate)

return (_ctx: any,_cache: any) => {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(_component_b_form, {
    onSubmit: _withModifiers(creation, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_form_group, {
        label: "Entrez votre email",
        "label-for": "courriel"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            id: "courriel",
            modelValue: courriel.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
            type: "email",
            required: "",
            onInput: evaluationEmail,
            state: isCourrielValide.value
          }, null, 8, ["modelValue", "state"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_form_group, {
        label: "Date de naissance, vous devez avoir 15 ans minimum",
        "label-for": "datenaissance"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(DatePicker), {
            id: "datenaissance",
            placeholder: "JJ/MM/AAAA",
            format: "DD/MM/YYYY",
            "value-type": "date",
            value: dateNaissance.value,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((dateNaissance).value = $event)),
            "default-value": moinsQuinzeAns,
            "disabled-date": seulementAvantQuinzeAns,
            lang: _unref(frLocale),
            class: "mb-2"
          }, null, 8, ["value", "lang"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_alert, {
        modelValue: afficherCreationOk.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((afficherCreationOk).value = $event)),
        variant: "primary"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Le courriel est créé")
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_alert, {
        modelValue: afficherCreationEchec.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((afficherCreationEchec).value = $event)),
        variant: "warning"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("L'e-mail saisit existe déjà dans nos bases")
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_alert, {
        modelValue: isErreurCreation.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isErreurCreation).value = $event)),
        variant: "danger"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(messageErreurCreation.value), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_button, {
        type: "submit",
        variant: "primary",
        disabled: !isFormulaireValide.value || !isValitationEnabled.value
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" Créer ")
        ])),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}
}

})