import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-flex"} }
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-eye"
}
const _hoisted_3 = {
  key: 1,
  class: "fa fa-eye-slash"
}

import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
import { ConfigVerifPassword} from "@/utils/ConfigVerifPassword";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinalisationRecuperationCompte',
  setup(__props) {

const router = useRouter();
const password = ref('');
const courriel = ref('');
const isPaswordValid = ref(false);
const motPasseVisible = ref(false)
const verifPassword = new ConfigVerifPassword()
const modifierMotPasseVisible = () => motPasseVisible.value = !motPasseVisible.value
const gestionCompteStore = useGestionCompteStore()
const isErreurCode = ref(false);
const isErreurRecuperationCompte = ref(false);
const isErreurRecupInfo = ref(false);
const messageErreurRecuperationCompte = ref('')
const messageErreurRecupInfo = ref('')
const route = useRoute()
const code = computed(() => route.params.code)
const isValitationEnabled = ref(true)

onMounted(async () => {
  if (typeof code.value !== 'string') {
    isErreurCode.value = true;
    return;
  }
  let resultatRecup = await gestionCompteStore.donneCourrielIndividuByCode(code.value);
  if (resultatRecup.erreur != null) {
    messageErreurRecupInfo.value = resultatRecup.erreur.message
    return;
  }
  courriel.value = resultatRecup.courriel;
  isErreurRecupInfo.value = false;
});

const evaluationPassword = () => {
  isPaswordValid.value = verifPassword.isPasswordValid(password.value);
}

const recuperationCompte = async () => {
  isValitationEnabled.value = false;
  if (typeof code.value == 'string' && isPaswordValid.value) {
    const resultatRecuperationCompte = await gestionCompteStore.recuperationCompte(code.value, password.value)
    if (resultatRecuperationCompte.erreur != null) {
      messageErreurRecuperationCompte.value = resultatRecuperationCompte.erreur.message
      isErreurRecuperationCompte.value = true;
    } else if (!resultatRecuperationCompte.reussi) {
      isErreurRecuperationCompte.value = true;
      messageErreurRecuperationCompte.value = "La récupération du compte a échoué";
    } else {
      isErreurRecuperationCompte.value = false;
      await router.push({ path: '/' });
    }
  }
  isValitationEnabled.value = true
};



return (_ctx: any,_cache: any) => {
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "justify-content-md-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { md: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_card, { title: "Finalisation de la modification du mot de passe" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_alert, {
                    modelValue: isErreurRecupInfo.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isErreurRecupInfo).value = $event)),
                    variant: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(messageErreurRecupInfo.value), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_b_alert, {
                    modelValue: isErreurCode.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isErreurCode).value = $event)),
                    variant: "warning"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Le code ne semble pas être formatté correctement")
                    ])),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_b_form, {
                    onSubmit: _withModifiers(recuperationCompte, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_group, {
                        label: "Email",
                        "label-for": "email"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "email",
                            modelValue: courriel.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((courriel).value = $event)),
                            type: "email",
                            required: "",
                            disabled: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_form_group, {
                        label: "Password",
                        "label-for": "password"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createVNode(_component_b_form_input, {
                              id: "password",
                              modelValue: password.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
                              type: motPasseVisible.value ? 'text' : 'password',
                              style: {"display":"flex"},
                              required: "",
                              onInput: evaluationPassword,
                              state: isPaswordValid.value
                            }, null, 8, ["modelValue", "type", "state"]),
                            _createVNode(_component_b_button, {
                              type: "button",
                              size: "sm",
                              variant: "primary",
                              onClick: modifierMotPasseVisible,
                              style: {"display":"flex"}
                            }, {
                              default: _withCtx(() => [
                                (motPasseVisible.value)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                                  : _createCommentVNode("", true),
                                (!motPasseVisible.value)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_alert, {
                        modelValue: isErreurRecuperationCompte.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isErreurRecuperationCompte).value = $event)),
                        variant: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(messageErreurRecuperationCompte.value), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_b_button, {
                        type: "submit",
                        variant: "primary",
                        disabled: !isPaswordValid.value || !isValitationEnabled.value
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Modifier le mot de passe")
                        ])),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})