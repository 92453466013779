<template>
  <b-container class="mt-5">
    <b-row class="justify-content-md-center">
      <b-col md="6">
        <b-card title="Finalisation de la création du compte">
          <b-alert v-model="isErreurRecupInfo" variant="danger">{{ messageErreurRecupInfo }}</b-alert>
          <b-alert v-model="isErreurCode" variant="warning">Le code ne semble pas être formatté correctement</b-alert>
          <b-form @submit.prevent="creation">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="courriel" type="email" required disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Pseudonyme" label-for="pseudonyme">
              <b-form-input id="pseudonyme" v-model="pseudonyme" type="text" @input="evaluationPseudonyme" :state="isPseudonymeValid"></b-form-input>
            </b-form-group>
            <b-form-group label="Password" label-for="password">
              <div style="display: inline-flex;">
                <b-form-input id="password" v-model="password" :type="motPasseVisible ? 'text' : 'password'"
                              required style="display: flex ;"  @input="evaluationPassword" :state="isPaswordValid"/>
                <b-button type="button" size="sm" variant="primary" @click="modifierMotPasseVisible" style="display: flex ;">
                  <i v-if="motPasseVisible" class="fa-solid fa-eye"></i>
                  <i v-if="!motPasseVisible" class="fa fa-eye-slash"></i>
                </b-button>
              </div>
            </b-form-group>
            <b-alert v-model="isErreurCreation" variant="danger">{{ messageErreurCreation }}</b-alert>
            <b-button type="submit" variant="primary" :disabled="!isFormulaireValid || !isValitationEnabled" >Creer</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
import { ConfigVerifPassword } from "@/utils/ConfigVerifPassword";

const router = useRouter();
const courriel = ref('');
const password = ref('');
const pseudonyme = ref('');
const isPaswordValid = ref(false);
const isPseudonymeValid = ref(false)
const isFormulaireValid = ref(false)
const motPasseVisible = ref(false)
const verifPassword = new ConfigVerifPassword()
const modifierMotPasseVisible = () => motPasseVisible.value = !motPasseVisible.value
const gestionCompteStore = useGestionCompteStore()
const isErreurCode = ref(false);
const isErreurCreation = ref(false);
const isErreurRecupInfo = ref(false);
const messageErreurCreation = ref('')
const messageErreurRecupInfo = ref('')
const route = useRoute()
const code = computed(() => route.params.code)
const isValitationEnabled = ref(true);


onMounted(async () => {
  if (typeof code.value !== 'string') {
    isErreurCode.value = true;
    return;
  }
  let resultatRecup = await gestionCompteStore.donneCourrielPreIndividuByCode(code.value);
  if (resultatRecup.erreur != null) {
    messageErreurRecupInfo.value = resultatRecup.erreur.message
    return;
  }
  courriel.value = resultatRecup.courriel;
  isErreurRecupInfo.value = false;
});

const evaluationPassword = () => {
  isPaswordValid.value = verifPassword.isPasswordValid(password.value);
  evaluationFormulaire()
}
const evaluationPseudonyme = () => {
  isFormulaireValid.value = isPseudonymeValid.value = pseudonyme?.value.length!=0 && pseudonyme.value.length<=30
  evaluationFormulaire()
}
const evaluationFormulaire =() => {
  isFormulaireValid.value = isPaswordValid.value && isPseudonymeValid.value
}

const creation = async () => {
  isValitationEnabled.value = false
  if (typeof code.value == 'string'&& isFormulaireValid.value) {
    const resultatCreation = await gestionCompteStore.creationCompte(code.value, pseudonyme.value, password.value)
    if (resultatCreation.erreur != null) {
      messageErreurCreation.value = resultatCreation.erreur.message
      isErreurCreation.value = true;
    } else if (!resultatCreation.reussi) {
      isErreurCreation.value = true;
      messageErreurCreation.value = "La création du compte a échoué, un individu portant cet e-mail existe peut-être déjà";
    } else {
      isErreurCreation.value = false;
      await router.push({ path: '/' });
    }
  }
  isValitationEnabled.value = true
};
</script>