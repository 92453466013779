<!--<template>
  <b-card header-bg-variant="primary">
    <b-card-header header-tag="nav" class="bg-primary text-white">
      <b-nav sticky="true" card-header="true" tabs="true">
        <b-nav-item>
          <router-link to="/" class="text-white"><i class="fa-solid fa-house"></i></router-link>
        </b-nav-item>
        <b-nav-item>
          Œuvres
        </b-nav-item>
        <b-nav-item>
          Ateliers d'écriture
        </b-nav-item>
        <b-nav-item>
          Auteurs
        </b-nav-item>
        <b-nav-item>
          Discussions
        </b-nav-item>
        <b-nav-item-dropdown v-if="individuConnecte.lstRole.includes('ADMIN')" class="text-white" text="Admin">
          <b-dropdown-item-button>
            Gestion des administrateurs
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            Gestion des modérateurs
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            <router-link :to="{ name: 'admin-genre' }">Gestion des genres</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            <router-link :to="{ name: 'admin-etiquette' }">Gestion des étiquettes</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            Gestion des statuts
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            Gestion des visibilite
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            <router-link :to="{ name: 'admin-role' }">Gestion des roles</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            <router-link :to="{ name: 'admin-droit' }">Gestion des droits</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            <router-link :to="{ name: 'admin-donnee' }">Gestion des données</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button>
            Gestion des types d'œuvres
          </b-dropdown-item-button>
        </b-nav-item-dropdown>
        <b-nav-item v-if="individuConnecte.id==null" to="/connexion">
          <i class="fa-solid fa-plug"></i>
        </b-nav-item>
        <b-nav-item v-if="individuConnecte.id !=null" @click="deconnexion">
          <i class="fa-solid fa-plug-circle-xmark  bg-primary text-white"></i>
        </b-nav-item>
      </b-nav>
    </b-card-header>
    <router-view />
  </b-card>
</template>-->
<template>
  <b-card class="mb-3">
      <b-tabs id="navglobale" active-nav-item-class="bg-primary text-white" nav-class="bg-primary text-white"  sticky="true">
        <b-tab @click.prevent="$router.push('/')">
          <template #title>
            <i class='fa-solid fa-house'></i>
          </template>
        </b-tab>
        <b-tab title="Œuvres" @click.prevent="$router.push('/recherche-oeuvre/')"/>
        <b-tab title="Ateliers d'écriture" @click.prevent="$router.push('/recherche-atelier/')"/>
        <b-tab title="Auteurs" @click.prevent="$router.push('/recherche-auteur/')"/>
        <b-tab title="Discussions" @click.prevent="$router.push('/recherche-discussion/')"/>
        <b-tab v-if="storeIndividuConnecte.individuConnecte?.id==null" @click.prevent="$router.push('/connexion')">
          <template #title>
            <i class='fa-solid fa-plug'></i>
          </template>
        </b-tab>
        <b-tab v-if="storeIndividuConnecte.individuConnecte?.id !=null" @click.prevent="deconnexion">
          <template #title>
            <i class='fa-solid fa-plug-circle-xmark'></i>
          </template>
        </b-tab>
      </b-tabs>
    <router-view />
  </b-card>
</template>



<style lang="scss">
#navglobale .nav .nav-item .nav-link {
  color: white; /* Text color for inactive tabs */
}

.global-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050; /* Pour qu'elle soit au-dessus d'autres éléments */
}
</style>

<script setup lang="ts">
import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
    recupereInfoConnexion()
)
</script>
