import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const storeIndividuConnecte = useIndividuConnecteStore()


const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({ path: '/connexion' });
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

// Vérification de la connexion au chargement de l'application
onMounted(() =>
    recupereInfoConnexion()
)

return (_ctx: any,_cache: any) => {
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, { class: "mb-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_tabs, {
        id: "navglobale",
        "active-nav-item-class": "bg-primary text-white",
        "nav-class": "bg-primary text-white",
        sticky: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_tab, {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push('/')), ["prevent"]))
          }, {
            title: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("i", { class: "fa-solid fa-house" }, null, -1)
            ])),
            _: 1
          }),
          _createVNode(_component_b_tab, {
            title: "Œuvres",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push('/recherche-oeuvre/')), ["prevent"]))
          }),
          _createVNode(_component_b_tab, {
            title: "Ateliers d'écriture",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$router.push('/recherche-atelier/')), ["prevent"]))
          }),
          _createVNode(_component_b_tab, {
            title: "Auteurs",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$router.push('/recherche-auteur/')), ["prevent"]))
          }),
          _createVNode(_component_b_tab, {
            title: "Discussions",
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$router.push('/recherche-discussion/')), ["prevent"]))
          }),
          (_unref(storeIndividuConnecte).individuConnecte?.id==null)
            ? (_openBlock(), _createBlock(_component_b_tab, {
                key: 0,
                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$router.push('/connexion')), ["prevent"]))
              }, {
                title: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "fa-solid fa-plug" }, null, -1)
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
            ? (_openBlock(), _createBlock(_component_b_tab, {
                key: 1,
                onClick: _withModifiers(deconnexion, ["prevent"])
              }, {
                title: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("i", { class: "fa-solid fa-plug-circle-xmark" }, null, -1)
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})