import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import 'vue-datepicker-next/index.css';
import { useGestionCompteStore } from "@/store/gestion-compte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'DemandeRecuperationCompte',
  setup(__props) {

const afficherRecuperationOk = ref(false);
const afficherRecuperationEchec = ref(false);
const isErreurRecuperation = ref(false);
const messageErreur = ref('')
const gestionCompteStore = useGestionCompteStore()
const isCourrielValide = ref(false)
const isValitationEnabled = ref(true)

const courriel = ref("")

const evaluationCourriel = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100
}

const recuperation = async () => {
  isValitationEnabled.value =false
  afficherRecuperationOk.value = false
  afficherRecuperationEchec.value = false
  isErreurRecuperation.value = false
  const resultat = await gestionCompteStore.demandeRecuperationCompte(courriel.value);
  if (resultat.erreur != null) {
    messageErreur.value = resultat.erreur.message
    isErreurRecuperation.value = true
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = false
  } else if (resultat.reussi == true) {
    afficherRecuperationOk.value = true
    afficherRecuperationEchec.value = false
  } else {
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = true
  }
  isValitationEnabled.value = true
};

return (_ctx: any,_cache: any) => {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(_component_b_form, {
    onSubmit: _withModifiers(recuperation, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_form_group, {
        label: "Entrez votre email",
        "label-for": "courriel"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            id: "courriel",
            modelValue: courriel.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
            type: "email",
            required: "",
            onInput: evaluationCourriel,
            state: isCourrielValide.value
          }, null, 8, ["modelValue", "state"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_alert, {
        modelValue: afficherRecuperationOk.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((afficherRecuperationOk).value = $event)),
        variant: "primary"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Un massage de récupération va vous être envoyez, cliquez sur le lien ")
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_alert, {
        modelValue: afficherRecuperationEchec.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((afficherRecuperationEchec).value = $event)),
        variant: "warning"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Erreur, votre compte existe-t-il déjà ?")
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_alert, {
        modelValue: isErreurRecuperation.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isErreurRecuperation).value = $event)),
        variant: "danger"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(messageErreur.value), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_button, {
        type: "submit",
        variant: "primary",
        disabled: !isCourrielValide.value || !isValitationEnabled.value
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Créer ")
        ])),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}
}

})