<template>
  <b-form @submit.prevent="recuperation">
    <b-form-group label="Entrez votre email" label-for="courriel">
      <b-form-input id="courriel" v-model="courriel" type="email" required @input="evaluationCourriel"
                    :state="isCourrielValide"></b-form-input>
    </b-form-group>
    <b-alert v-model="afficherRecuperationOk" variant="primary">Un massage de récupération va vous être envoyez, cliquez
      sur le lien
    </b-alert>
    <b-alert v-model="afficherRecuperationEchec" variant="warning">Erreur, votre compte existe-t-il déjà ?</b-alert>
    <b-alert v-model="isErreurRecuperation" variant="danger">
      {{ messageErreur }}
    </b-alert>
    <b-button type="submit" variant="primary" :disabled="!isCourrielValide || !isValitationEnabled">
      Créer
    </b-button>
  </b-form>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { ref } from "vue";
import 'vue-datepicker-next/index.css';
import { useGestionCompteStore } from "@/store/gestion-compte-store";

const afficherRecuperationOk = ref(false);
const afficherRecuperationEchec = ref(false);
const isErreurRecuperation = ref(false);
const messageErreur = ref('')
const gestionCompteStore = useGestionCompteStore()
const isCourrielValide = ref(false)
const isValitationEnabled = ref(true)

const courriel = ref("")

const evaluationCourriel = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100
}

const recuperation = async () => {
  isValitationEnabled.value =false
  afficherRecuperationOk.value = false
  afficherRecuperationEchec.value = false
  isErreurRecuperation.value = false
  const resultat = await gestionCompteStore.demandeRecuperationCompte(courriel.value);
  if (resultat.erreur != null) {
    messageErreur.value = resultat.erreur.message
    isErreurRecuperation.value = true
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = false
  } else if (resultat.reussi == true) {
    afficherRecuperationOk.value = true
    afficherRecuperationEchec.value = false
  } else {
    afficherRecuperationOk.value = false
    afficherRecuperationEchec.value = true
  }
  isValitationEnabled.value = true
};
</script>
