<template>
  <div class="contenant">
    <b-card title="Connexion" tag="article" class="contenu">
      <b-form @submit.prevent="connexion">
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="courriel" type="email" required></b-form-input>
        </b-form-group>
        <b-form-group label="Password" label-for="password">
          <b-form-input id="password" v-model="password" type="password" required></b-form-input>
        </b-form-group>
        <b-alert :v-model="individuConnectestore.erreurConnexion == null" variant="danger">
          {{ individuConnectestore.erreurConnexion?.message }}
        </b-alert>
        <b-button type="submit" variant="primary">Connexion</b-button>
      </b-form>
      <p>
        <b-link @click="showCreationModal()">Créer un compte</b-link>
      </p>
      <p>
        <b-link @click="showRecuperationModal()">J'ai oublié mon mot de passe</b-link>
      </p>
      <b-modal id="modal-1" title="Création de compte" v-model="isCreationModalVisible" hide-footer="true">
        <DemandeCreationCompteComposant />
      </b-modal>
      <b-modal id="modal-1" title="Récupération de compte" v-model="isRecuperationModalVisible" hide-footer="true">
        <DemandeRecuperationCompte />
      </b-modal>

    </b-card>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import DemandeCreationCompteComposant from "@/pages/DemandeCreationCompte.vue";
import DemandeRecuperationCompte from "@/pages/DemandeRecuperationCompte.vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";
const router = useRouter();
const courriel = ref("");
const password = ref("");
const individuConnectestore = useIndividuConnecteStore()
const gestionCompteStore = useGestionCompteStore()
const isCreationModalVisible = ref(false);
const isRecuperationModalVisible = ref(false);
const isErreurConnexion = ref(false)
const messageErreurConnexion = ref("")
const showCreationModal = () => {
  isCreationModalVisible.value = true;
};
const showRecuperationModal = () => {
  isRecuperationModalVisible.value = true;
};
const connexion = async () => {
  const resultatConnexion = await gestionCompteStore.connexion(courriel.value, password.value);
  if (resultatConnexion.erreur != null) {
    isErreurConnexion.value = true
    messageErreurConnexion.value = resultatConnexion.erreur.message;
    return;
  }
  isErreurConnexion.value = false;
  await individuConnectestore.connecterIndividu()
  if(individuConnectestore.individuConnecte != null) {
    await router.push({ path: '/' });
  }
}
</script>


<style scoped lang="scss">
.contenant {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // 100% de la hauteur de la vue
  margin: 0;
  background-image: url('@/assets/background.jpg');
  background-size: cover; // Ajuste l'image pour couvrir tout l'élément
  background-position: center; // Centre l'image dans l'élément
  background-repeat: no-repeat; // Évite que l'image se répète
}


.contenu {
  width: 25rem;
}

</style>
