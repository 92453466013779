<template>
  <b-card :title="storeIndividuPresentation?.individuSelectionne?.pseudonyme">
    <b-row no-gutters>
      <!-- Colonne pour l'image ou l'icône -->
      <b-col cols="auto" class="d-flex align-items-center">
        <b-card-img
          v-if="storeIndividuPresentation.individuSelectionne?.imagePresentation"
          :src="storeIndividuPresentation.individuSelectionne?.imagePresentation"
          class="w-100"
          alt="Image de présentation"
          style="max-width: 150px;"></b-card-img>
        <i v-else class="fa-solid fa-user fa-3x"  style="width: 100px; height: 100px; display: flex; justify-content: center; align-items: center;"
          aria-hidden="true"
        ></i>
      </b-col>

      <!-- Colonne pour le texte -->
      <b-col>
        <b-card-body>
          <div v-html="storeIndividuPresentation.individuSelectionne?.presentation"></div>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
  <b-tabs tabs>
    <b-tab @click.prevent="$router.push('/individu/'+id+'/texte/')" title="Textes" />
    <b-tab @click.prevent="$router.push('/individu/'+id+'/lecture/')" title="Lectures" />
    <b-tab @click.prevent="$router.push('/individu/'+id+'/atelier/')" title="Ateliers" />
    <b-tab @click.prevent="$router.push('/individu/'+id+'/discussion/')" title="Discussions" />
    <b-tab @click.prevent="$router.push('/individu/'+id+'/bibliotheque/')" title="Bibliothèque " />
    <b-tab v-if="isIndividuConnecte" @click.prevent="$router.push('/individu/'+id+'/configuration/')">
      <template #title>
        <i class="fa-solid fa-gear"></i>
      </template>
    </b-tab>
  </b-tabs>
  <router-view></router-view>
</template>

<style scoped lang="scss">

</style>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()

const isIndividuConnecte = ref(false)


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
  } catch (error) {
    console.log("OnMounted Error");
  }
})
</script>