<template>
  <b-card title="Vos informations publiques">
    <b-form @submit.prevent="modifierInfoPub">
      <b-form-group label="Changer le pseudonyme">
        <b-form-input type="text" v-model="nouveauPseudonyme"></b-form-input>
      </b-form-group>
      <b-alert v-model="estPseudonymeErreur" variant="danger" dismissible="true">Pseudonyme trop long, maximum 30 caractères</b-alert>
      <b-form-group label="Changer le texte de la présentation">
        <quill-editor v-model:content="nouveauTextePresentation" theme="snow" contentType="html" />
      </b-form-group>
      <b-alert v-model="estPresentationErreur" variant="danger" dismissible="true">Texte trop long, maximum 500 caractère, balises html comprises</b-alert>

      <b-form-group>
        <div class="col-12">
          <b-button @click.prevent="afficherRecadrerImage">Changer l'image</b-button>
        </div>
        <div class="col-12 mt-3">
          <b-img :src="nouvelleImageContenu"></b-img>
        </div>
      </b-form-group>
      <b-alert v-model="estErreurTaille"  variant="danger" dismissible="true">"{{imageErreurTaille?.message}}"</b-alert>
      <b-alert v-model="estErreurDimension" variant="danger" dismissible="true" class="mt-3">{{imageErreurDimension?.message}}</b-alert>
      <b-alert v-model="storeIndividuPresentation.estErreurSelection" variant="danger" dismissible="true">
        {{ storeIndividuPresentation?.erreurSelection?.message}}
      </b-alert>
      <b-modal v-model="afficheRecadreurImage" title="Découpez votre image" size="lg" hide-footer="true">
          <RecadreurImage
            :hauteur-stencil=400
            :largeurStencil=400
            @recadre="onImageRecadre"
          />
      </b-modal>
      <b-button type="submit" variant="primary" :disabled="estPseudonymeErreur || estPresentationErreur || estErreurTaille || estErreurDimension">Sauvegarder les
        modifications
      </b-button>
    </b-form>
  </b-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

const route = useRoute()
const id = Number(route.params.id);
import { useRoute } from "vue-router";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { Erreur } from "@/utils/Erreur";
import RecadreurImage from "@/outil-compposant/RecadreurImage.vue";


const storeIndividuPresentation = useIndividuPresentationStore()
const nouveauPseudonyme = ref("")
const nouveauTextePresentation = ref("")
const nouvelleImageContenu = ref("");
const imageErreurTaille = ref<Erreur|null>(new Erreur("blu"));
const imageErreurDimension = ref<Erreur|null>(null);
const estErreurTaille = ref(false);
const estErreurDimension = ref(false);
const afficheRecadreurImage = ref(false)

const onImageRecadre = (imageRecadree: string) => {
  nouvelleImageContenu.value = imageRecadree; // Stocker l'image recadrée
  afficheRecadreurImage.value = false; // Fermer la modal après recadrage
};

const afficherRecadrerImage = () => afficheRecadreurImage.value=true;

const estPseudonymeErreur = computed(() => {
  return nouveauPseudonyme.value.length == 0 || nouveauPseudonyme.value.length > 30
})
const estPresentationErreur = computed(() => {
  if(!nouveauTextePresentation.value) return false;
  return nouveauTextePresentation.value.length > 500
})
const modifierInfoPub = async () => {
   await storeIndividuPresentation.modifier(nouveauPseudonyme.value, nouveauTextePresentation.value, nouvelleImageContenu.value);
}


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    if (storeIndividuPresentation.individuSelectionne) {
      nouveauPseudonyme.value = storeIndividuPresentation.individuSelectionne.pseudonyme
      nouveauTextePresentation.value = storeIndividuPresentation.individuSelectionne.presentation
      nouvelleImageContenu.value = storeIndividuPresentation.individuSelectionne.imagePresentation
    }
  } catch (error) {
    console.log("OnMounted Error");
  }
})
</script>


<style scoped lang="scss">

</style>