import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "fa-solid fa-user fa-3x",
  style: {"width":"100px","height":"100px","display":"flex","justify-content":"center","align-items":"center"},
  "aria-hidden": "true"
}
const _hoisted_2 = ["innerHTML"]

import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuProfil',
  setup(__props) {

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()

const isIndividuConnecte = ref(false)


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
  } catch (error) {
    console.log("OnMounted Error");
  }
})

return (_ctx: any,_cache: any) => {
  const _component_b_card_img = _resolveComponent("b-card-img")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_b_card, {
      title: _unref(storeIndividuPresentation)?.individuSelectionne?.pseudonyme
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_row, { "no-gutters": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              cols: "auto",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                (_unref(storeIndividuPresentation).individuSelectionne?.imagePresentation)
                  ? (_openBlock(), _createBlock(_component_b_card_img, {
                      key: 0,
                      src: _unref(storeIndividuPresentation).individuSelectionne?.imagePresentation,
                      class: "w-100",
                      alt: "Image de présentation",
                      style: {"max-width":"150px"}
                    }, null, 8, ["src"]))
                  : (_openBlock(), _createElementBlock("i", _hoisted_1))
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_card_body, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _unref(storeIndividuPresentation).individuSelectionne?.presentation
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_b_tabs, { tabs: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_tab, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/texte/')), ["prevent"])),
          title: "Textes"
        }),
        _createVNode(_component_b_tab, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/lecture/')), ["prevent"])),
          title: "Lectures"
        }),
        _createVNode(_component_b_tab, {
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/atelier/')), ["prevent"])),
          title: "Ateliers"
        }),
        _createVNode(_component_b_tab, {
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/discussion/')), ["prevent"])),
          title: "Discussions"
        }),
        _createVNode(_component_b_tab, {
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/bibliotheque/')), ["prevent"])),
          title: "Bibliothèque "
        }),
        (isIndividuConnecte.value)
          ? (_openBlock(), _createBlock(_component_b_tab, {
              key: 0,
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$router.push('/individu/'+_unref(id)+'/configuration/')), ["prevent"]))
            }, {
              title: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "fa-solid fa-gear" }, null, -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_router_view)
  ], 64))
}
}

})