import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useIndividuConnecteStore } from '@/store/individu-connecte-store';
import ConnexionCompte from "@/pages/ConnexionCompte.vue";
import HomeVide from "@/pages/HomeVide.vue";
import IndividuProfil from "@/pages/IndividuProfil.vue";
import IndividuConfiguration from "@/pages/profil/IndividuConfiguration.vue";
import FinalisationCreationCompte from "@/pages/FinalisationCreationCompte.vue";
import FinalisationRecuperationCompte from "@/pages/FinalisationRecuperationCompte.vue"; // Assurez-vous que le chemin est correct


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeVide",
    component: HomeVide,
    async beforeEnter() {
      const storeIndividuConnecte = useIndividuConnecteStore()
      if (!storeIndividuConnecte.individuConnecte) {
        return{
          path: '/connexion/'
        }
      }
      return {
        name: "individu-profil",
        params: { id: storeIndividuConnecte.individuConnecte.id }
      }
    }
  },
  {
    path: "/individu/:id",
    name: "individu-profil",
    component: IndividuProfil,
    children: [
      {
        path: "configuration/",
        name: "individu-configuration",
        component: IndividuConfiguration,
      },
      ]
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: ConnexionCompte
  },
  {
    path: "/creation-compte/:code",
    name: "FinalisationCreationCompte",
    component: FinalisationCreationCompte
  },
  {
    path: "/recuperation-compte/:code",
    name: "FinalisationRecuperationCompte",
    component: FinalisationRecuperationCompte
  },

]

/* children: [
   {
     path: "/individu/:id",
     name: "information-auteur",
     component: InformationAuteur,
     children:[
       {
         path: "",
         name: "information-presentation-auteur",
         component: PresentationAuteur,
       },
       {
         path: "/information/activite/",
         name: "information-activite-auteur",
         component: ActiviteAuteur,
       },
       {
         path: "/information/gestion-personnel/",
         name: "information-gestion-personnel-auteur",
         component: GestionInformationPersonnelle,
       },
       {
         path: "/information/gestion-autorisation",
         name: "information-gestion-autorisation-auteur",
         component: GestionBlocageAutorisation,
       },
     ]
   },
   {
     path: "/texte/",
     name: "texte-auteur",
     component: TexteAuteur
   },
   {
     path: "/lecture/",
     name: "lecture-en-cours-auteur",
     component: LectureEnCoursAuteur
   },
   {
     path: "/atelier/",
     name: "atelier-ecriture-auteur",
     component: AtelierEcritureAuteur
   },
   {
     path: "/discussion/",
     name: "discussion-auteur",
     component: DiscussionAuteur
   },
   {
     path: "/bibliotheque/",
     name: "bibliotheque-auteur",
     component: BibliothequeAuteur
   }
 ],


},
{
 path: "/connexion",
 name: "Connexion",
 component: Connexion
},
{
 path: "/creation-compte/:code",
 name: "FinalisationCreationCompte",
 component: FinalisationCreationCompte
},
{
 path: "/recuperation-compte/:code",
 name: "FinalisationRecuperationCompte",
 component: FinalisationRecuperationCompte
},
{
 path: "/recherche-oeuvre/",
 name: "RechercheOeuvre",
 component: RechercheOeuvre
},
{
 path: "/recherche-auteur/",
 name: "RechercheAuteur",
 component: RechercheAuteur
},
{
 path: "/recherche-atelier/",
 name: "RechercheAtelier",
 component: RechercheAtelier
},
{
 path: "/recherche-discussion/",
 name: "RechercheDiscussion",
 component: RechercheDiscussion
},
{
 path: "/admin",
 name: "Admin",
 component: Admin,
 async beforeEnter() {
   const storeIndividuConnecte = useIndividuConnecteStore()
   const hasRoleAdmin = storeIndividuConnecte.hasRole('ADMIN')
   if (!hasRoleAdmin) return { name: 'Login' };
   return true;
 },
 children: [
   {
     path: "/admin/genre",
     name: "admin-genre",
     component: AdminGenreLitteraire
   },
   {
     path: "/admin/etiquette",
     name: "admin-etiquette",
     component: AdminEtiquette
   },
   {
     path: "/admin/donnee",
     name: "admin-donnee",
     component: AdminDonnee
   },
   {
     path: "/admin/droit",
     name: "admin-droit",
     component: AdminDroit
   },
   {
     path: "/admin/role",
     name: "admin-role",
     component: AdminRole
   }
 ]*/


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
