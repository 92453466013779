<template>
  <b-form @submit.prevent="creation">
    <b-form-group label="Entrez votre email" label-for="courriel">
      <b-form-input id="courriel" v-model="courriel" type="email" required  @input="evaluationEmail"
                    :state="isCourrielValide"></b-form-input>
    </b-form-group>
    <b-form-group label="Date de naissance, vous devez avoir 15 ans minimum" label-for="datenaissance">
      <date-picker id="datenaissance" placeholder="JJ/MM/AAAA" format="DD/MM/YYYY" value-type="date"
                   v-model:value="dateNaissance" :default-value="moinsQuinzeAns"
                   :disabled-date="seulementAvantQuinzeAns" :lang="frLocale" class="mb-2"></date-picker>
    </b-form-group>
    <b-alert v-model="afficherCreationOk" variant="primary">Le courriel est créé</b-alert>
    <b-alert v-model="afficherCreationEchec" variant="warning">L'e-mail saisit existe déjà dans nos bases</b-alert>
    <b-alert v-model="isErreurCreation" variant="danger">{{ messageErreurCreation }}
    </b-alert>
    <b-button type="submit" variant="primary" :disabled="!isFormulaireValide || !isValitationEnabled">
      Créer
    </b-button>
  </b-form>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { Ref, ref, watch } from "vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import frLocale from 'vue-datepicker-next/locale/fr';
import { useGestionCompteStore } from "@/store/gestion-compte-store";

const afficherCreationOk = ref(false);
const afficherCreationEchec = ref(false);
const isErreurCreation = ref(false);
const messageErreurCreation = ref("")
const isErreurDate = ref(true);
const courriel = ref("");
const isCourrielValide = ref(false);
const isFormulaireValide = ref(false);
const isValitationEnabled = ref(true);
const gestionCompteStore = useGestionCompteStore()

const moinsQuinzeAns = () => {
  let maintenant = new Date();
  maintenant.setHours(0, 0, 0, 0);
  return new Date(maintenant.getTime() - 15 * 365 * 24 * 3600 * 1000)
}
const dateNaissance: Ref<null | Date> = ref(null)
const seulementAvantQuinzeAns = (date: Date) => {
  return date >= new Date(moinsQuinzeAns());
}
const evaluationEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100;
  evaluationFormulaire()
}
const evaluationDate = () => {
  console.log("evaluationDate")
  isErreurDate.value = dateNaissance.value == null
  evaluationFormulaire()
}
const evaluationFormulaire = () => {
  isFormulaireValide.value = isCourrielValide.value && !isErreurDate.value
}
const creation = async () => {
  isValitationEnabled.value = false
  afficherCreationOk.value = false
  if(isFormulaireValide.value && dateNaissance.value!=null) {
    const date = dateNaissance.value
    let isoDate = date.toISOString().split('T')[0];
    const resultat = await gestionCompteStore.creationPreIndividu(courriel.value, isoDate);
    if (resultat.erreur != null) {
      isErreurCreation.value = true
      afficherCreationOk.value = false
      afficherCreationEchec.value = false
      messageErreurCreation.value = resultat.erreur.message
    } else if (!resultat.reussi) {
      afficherCreationOk.value = false
      afficherCreationEchec.value = true
    } else {
      afficherCreationOk.value = true
      afficherCreationEchec.value = false
    }
  }
  isValitationEnabled.value= true
};
watch(dateNaissance, evaluationDate)
</script>
