import { createApp } from "vue";
import App from "./App.vue";
import index from "./router";
import { BootstrapVue3 } from "bootstrap-vue-3";
import { createPinia } from 'pinia'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
const pinia = createPinia()

createApp(App).use(pinia).use(index).use(BootstrapVue3).mount("#app");
